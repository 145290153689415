import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PathPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  let image;

  if (post.frontmatter.pathImage) {
    image = ( <Img fluid={{...post.frontmatter.pathImage.childImageSharp.fluid, aspectRatio: 3 / 2}} /> )
  } else {
    image = (<div>Placeholder</div>);
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="post">
        <div className="post__image">
          {image}
        </div>
        <div className="post__meta">
          <span className="post__coordinate">{post.frontmatter.latitude}</span>
          <span className="post__coordinate">{post.frontmatter.longitude}</span>
        </div>
      </article>
    </Layout>
  )
}

export default PathPostTemplate

export const pageQuery = graphql`
  query PathPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        latitude
        longitude
        pathImage {
          relativePath
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
